<template>
    <div class="flex">
        <p class="upLoad_text">图片：</p>
        <div class="upload_box">

            <el-upload :file-list="fileList" :accept="accept ? accept : '.jpg,.png'" :show-file-list="false"
                :on-exceed="handleExceed" :on-remove="handleRemove" :on-success="handleAvatarSuccess" @on-error="err"
                :limit="1" class="upload-demo" drag action="/api/common/upload" :headers="headerObj" :multiple="false">
                <div class="el-upload__text">
                    <!-- <p class="text">将文件拖拽到此区域上传</p> -->

                    <div v-if="fileName == ''" class="el-upload__tip" slot="tip">+ 上传图片</div>
                    <div v-else class="el-upload_tip_hint">已上传文件：{{ fileName.length > 12 ? fileName.slice(0, 12) + '...'
                +
                fileName.slice(-3) : fileName }}<span @click.stop="delete_img"></span></div>
                </div>
                <i class="el-icon-upload"></i>
                <!-- <em>点击上传</em> -->
            </el-upload>
            <!-- <p class="or">或</p> -->
        </div>
    </div>
</template>

<script>
export default {
    components: {
    },
    props: {
        // link: String,
        accept: String, //上传图片类型限制
    },
    created() { },
    mounted() {
        this.getUserInfo()
    },
    data() {
        return {
            headerObj: {
                'token': this.$user_info.token
            },
            fileName: '',
            fileList: [],
        }
    },
    methods: {
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handleAvatarSuccess(res, file) {
            this.fullscreenLoading = true
            if (res.code) {
                this.fullscreenLoading = false
                console.log(file);
                if (file.size / 1024 / 1024 < 10) {
                    this.$emit('getImg', res.data.fullurl)
                    this.fileName = file.name
                } else {
                    this.$message({
                        message: '上传图片大小不得超过10MB',
                        type: 'warning'
                    })
                }
                // if (file.name.length > 15) {
                //     this.fileName = file.name.slice(0, 15) + '...'
                // } else {
                // }
            } else {
                this.$message.error(res.data.msg);
                this.fullscreenLoading = false
            }

        },
        err(err, file, fileList) {
            console.log(err, file, fileList);
        },
        handleExceed(files, fileList) {
            console.log(fileList);
            this.$message.warning(`当前限制选择 1个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        handleChange(file, fileList) {
            console.log(file, fileList);
            // this.fileList = fileList.slice(-3);
            this.fileList = []
        },
        delete_img() {
            this.fileList = []
            this.fileName = ''
            this.$emit('getImg', '')
        }

    },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai';

.upload_box {
    width: auto;
    align-items: center;
    height: 40px;
    position: relative;

}

::v-deep .el-upload-dragger {
    height: 40px;
}

::v-deep .el-upload__tip {
    margin: 0 auto;
    width: 80%;
    line-height: 40px;
    white-space: nowrap; //不换行
    overflow: hidden; //超出部分隐藏
    text-overflow: ellipsis;
    text-align: center;
}

::v-deep .el-upload-dragger {
    border: 0;
}

::v-deep .el-upload_tip_hint {
    margin: 0 auto;
    width: 80%;
    line-height: 40px;
    // white-space: nowrap; //不换行
    // overflow: hidden; //超出部分隐藏
    // text-overflow: ellipsis;
    text-align: center;
    padding-right: 20px;
    box-sizing: border-box;
    position: relative;

    span {
        display: block;
        width: 15px;
        height: 15px;
        background: url('~@/assets/ai/close.png')no-repeat;
        background-size: 100%;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}
</style>